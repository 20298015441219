import { Component } from '@angular/core'
import {
  ExperimentalFeature,
  ExperimentalFeaturesService,
} from 'src/app/core/experimental.features.service'
import {
  AppLocale,
  LocalizationService,
} from 'src/app/core/localization.service'
import { UserService } from '../../core'

@Component({
  selector: 'kdgh-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.scss'],
})
export class MainNavBarComponent {
  constructor(
    public userService: UserService,
    private localization: LocalizationService,
    private experimentalFeatures: ExperimentalFeaturesService
  ) {}

  AppLocale = AppLocale

  get showLocalSelector() {
    return this.experimentalFeatures.isEnabled(ExperimentalFeature.localization)
  }

  get locale(): AppLocale {
    return this.localization.locale
  }

  get materialLink(): string {
    return `/material/${this.localization.materialBookSlugForLocale}`
  }

  selectLocale(locale: AppLocale) {
    this.localization.locale = locale
  }

  signIn() {
    this.userService.signIn()
  }

  signOut() {
    this.userService.signOut()
  }

  get localFlagSvg() {
    let flagCode: string
    switch (this.locale) {
      case AppLocale.en:
        flagCode = 'gb'
        break
      case AppLocale.de:
        flagCode = 'de'
        break
      case AppLocale.lt:
        flagCode = 'lt'
        break
    }
    return `assets/icons/flags/${flagCode}.svg`
  }
}
