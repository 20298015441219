import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { map, take } from 'rxjs/operators'
import { CourseLevel } from 'src/generated/graphql'
import { UserService } from '../core'
import { MaterialLevel } from '../core/contentful'
import { MaterialBookViewer } from './book-toc/book-toc.component'

@Injectable({
  providedIn: 'root',
})
export class ViewerResolver implements Resolve<MaterialBookViewer> {
  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.userAccount$.pipe(
      map(userAccount => {
        return {
          id: userAccount.id,
          isAdmin: !!userAccount.person.adminAppUser,
          unlockedMaterialLevels:
            userAccount.person.memberPortalUser.materialAccessCourseLevels.map(
              courseLevelToMaterialLevel
            ),
        } as MaterialBookViewer
      }),
      take(1)
    )
  }
}

function courseLevelToMaterialLevel(courseLevel: CourseLevel): MaterialLevel {
  switch (courseLevel) {
    case CourseLevel.Zero:
      return '0'
    case CourseLevel.One:
      return 'I'
    case CourseLevel.Two:
      return 'II'
    case CourseLevel.Three:
      return 'III'
    case CourseLevel.Four:
      return 'IV'
    case CourseLevel.Publications:
      return 'Publikationen'
  }
}
