import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { Entry, EntryFields } from 'contentful'
import * as htmlToText from 'html-to-text'
import * as XLSX from 'xlsx-js-style'
import {
  ContentfulService,
  MaterialBookFields,
  MaterialChapterFields,
  MaterialLevelFields,
  MaterialListFields,
} from '../core/contentful'

export async function saveContentOverview(
  book: Entry<MaterialBookFields>,
  contentfulService: ContentfulService,
  draft: boolean
) {
  const workbook = XLSX.utils.book_new()
  const sheet = await buildContentOverviewSheet(book, contentfulService, draft)
  XLSX.utils.book_append_sheet(workbook, sheet, 'Test')

  XLSX.writeFileXLSX(workbook, 'Material-Inhaltsübersicht.xlsx')
}

const colInfos: XLSX.ColInfo[] = [
  // Reihenfolge (incrementing counter)
  { wch: 8 },
  // Typ
  { wch: 16 },
  // Pfad
  { wch: 16 },
  // Stufe
  { wch: 4 },
  // Kapitelnummer
  { wch: 10 },
  // Blocknummer
  { wch: 10 },
  // Titel
  { wch: 60 },
  // Beschreibung
  { wch: 60 },
  // Dateien
  { wch: 60 },
  // Foto
  { wch: 60 },
  // Link
  { wch: 60 },
]
const header: XLSX.CellObject[] = [
  { t: 's', v: 'Reihenfolge' },
  { t: 's', v: 'Typ' },
  { t: 's', v: 'Pfad' },
  { t: 's', v: 'Stufe' },
  { t: 's', v: 'Kapitelnummer' },
  { t: 's', v: 'Blocknummer' },
  { t: 's', v: 'Titel' },
  { t: 's', v: 'Beschreibung' },
  { t: 's', v: 'Dateien' },
  { t: 's', v: 'Foto' },
  { t: 's', v: 'Link' },
]
const textStyle = {
  alignment: {
    wrapText: true,
    vertical: 'top',
    horizontal: 'left',
  },
}

export async function buildContentOverviewSheet(
  book: Entry<MaterialBookFields>,
  contentfulService: ContentfulService,
  draft: boolean
): Promise<XLSX.WorkSheet> {
  async function getFullChapters(
    chapters: Entry<MaterialChapterFields>[],
    contentful: ContentfulService
  ): Promise<Entry<MaterialChapterFields>[]> {
    const fullChapters = await contentful.getMaterialChapters({
      ids: chapters.map(chapter => chapter.sys.id),
      draft: draft,
    })

    return fullChapters as any as Entry<MaterialChapterFields>[]
  }

  async function buildChapterRows(
    id: number[],
    path: string[],
    level: Entry<MaterialLevelFields>,
    chapter: Entry<MaterialChapterFields>
  ): Promise<XLSX.CellObject[][]> {
    const chapterRows: XLSX.CellObject[][] = []

    chapterRows.push([
      { t: 's', v: 'Kapitel' },
      { t: 's', v: renderPath(path) },
      { t: 's', v: id[0].toString() },
      { t: 's', v: id.slice(1).join('.') },
      { t: 's', v: '' },
      { t: 's', v: chapter.fields.title, s: textStyle },
      {
        t: 's',
        v: renderRichText(chapter.fields.description),
        s: textStyle,
      },
      {
        t: 's',
        v: renderRichText(chapter.fields.description),
        s: textStyle,
      },
      {
        t: 's',
        v: '',
      },
      {
        t: 's',
        v: '',
      },
      {
        t: 's',
        v: chapter.fields.heroImage?.fields?.title as any,
      },
      {
        t: 's',
        v: chapter.sys.id,
        l: {
          Target: contentfulService.getEditUrlForEntry(chapter.sys.id),
        },
      },
    ])
    const materialLists = chapter.fields.body?.content
      ?.filter(it => it.nodeType === 'embedded-entry-block')
      ?.map(it => it.data.target as unknown as Entry<MaterialListFields>)
    if (materialLists) {
      for (const [i, materialList] of materialLists.entries()) {
        chapterRows.push([
          { t: 's', v: 'Material Liste' },
          { t: 's', v: renderPath([...path, materialList.fields.name]) },
          { t: 's', v: id[0].toString() },
          { t: 's', v: id.slice(1).join('.') },
          { t: 's', v: (i + 1).toString() },
          { t: 's', v: materialList.fields.name, s: textStyle },
          {
            t: 's',
            v: renderRichText(materialList.fields.description),
            s: textStyle,
          },
          {
            t: 's',
            v:
              materialList.fields.materials
                ?.map(it => it.fields.file.fields.title as any)
                ?.join('\n') ?? '',
          },
          {
            t: 's',
            v: '',
          },
          {
            t: 's',
            v: materialList.sys.id,
            l: {
              Target: contentfulService.getEditUrlForEntry(materialList.sys.id),
            },
          },
        ])
      }
    }

    const childChapters = chapter.fields.chapters
    if (childChapters) {
      const fullChildChapters = await getFullChapters(
        childChapters,
        contentfulService
      )

      const childChapterRows = await Promise.all(
        [...fullChildChapters.entries()].map(async ([i, childChapter]) =>
          buildChapterRows(
            [...id, i + 1],
            [...path, childChapter.fields.title],
            level,
            childChapter
          )
        )
      )

      chapterRows.push(...childChapterRows.flat())
    }

    return chapterRows
  }

  async function buildLevelRows(
    level: Entry<MaterialLevelFields>
  ): Promise<XLSX.CellObject[][]> {
    const fullLevelChapters = await getFullChapters(
      level.fields.chapters,
      contentfulService
    )

    const chaptersRows = await Promise.all(
      [...fullLevelChapters.entries()].map(async ([i, chapter]) =>
        buildChapterRows(
          [level.fields.level.length, i],
          [level.fields.name, chapter.fields.title],
          level,
          chapter
        )
      )
    )

    return chaptersRows.flat()
  }

  async function buildBookRows(
    book: Entry<MaterialBookFields>
  ): Promise<XLSX.CellObject[][]> {
    const levelsRows = await Promise.all(book.fields.levels.map(buildLevelRows))

    const bookRows = levelsRows.flat()

    // Insert incrementing counter column into each row.
    for (const [i, row] of bookRows.entries()) {
      row.unshift({ t: 's', v: i.toString() })
    }

    return bookRows
  }

  const rows: XLSX.CellObject[][] = [header, ...(await buildBookRows(book))]
  const sheet = XLSX.utils.aoa_to_sheet(rows)
  sheet['!cols'] = colInfos

  return sheet
}

function renderPath(path: string[]): string {
  return path.join(' / ')
}

const convertHtmlToText = htmlToText.compile()

function renderRichText(text?: EntryFields.RichText): string | undefined {
  if (text) {
    const html = documentToHtmlString(text as any)
    return convertHtmlToText(html)
  }
}
