import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { LocalizationService } from '../core/localization.service'

@Injectable({ providedIn: 'root' })
export class MaterialDefaultLocaleGuard implements CanActivate {
  constructor(
    private router: Router,
    private localizationService: LocalizationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.router.navigate([
      '/material',
      this.localizationService.materialBookSlugForLocale,
    ])
    return of(false)
  }
}
