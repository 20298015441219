import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { UserService } from '../core'

export interface MaterialAccessConfig {
  activateIf: 'hasAccess' | 'hasNoAccess'
  redirectTo: string
}

@Injectable({ providedIn: 'root' })
export class MaterialAccessGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const config = route.data as MaterialAccessConfig

    return this.userService.userAccount$.pipe(
      map(userAccount => {
        const materialAccessCourseLevels =
          userAccount?.person?.memberPortalUser?.materialAccessCourseLevels
        const hasAccess = materialAccessCourseLevels?.length > 0

        switch (config.activateIf) {
          case 'hasAccess':
            return hasAccess
          case 'hasNoAccess':
            return !hasAccess
        }
      }),
      tap(activate => {
        if (!activate) {
          this.router.navigate([config.redirectTo])
        }
      })
    )
  }
}
