import { EventEmitter, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { MaterialBookSlug } from '../material/material-routing.module'

const localeOverrideStorageKey = 'kdgh:memberPortal:locale'

export enum AppLocale {
  de = 'de',
  en = 'en',
  lt = 'lt',
}

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor() {
    const buildLocale = location.pathname.split('/')[1]
    if (buildLocale in AppLocale) {
      this._isLocalizedBuild = true
      this._locale = buildLocale as AppLocale
    } else {
      this._locale = this.localeOverride ?? AppLocale.de
    }
  }

  private _isLocalizedBuild = false

  get localeChange(): Observable<string> {
    return this._localeChange
  }
  private readonly _localeChange = new EventEmitter<string>()

  get locale(): AppLocale {
    return this._locale
  }
  private _locale: AppLocale

  set locale(locale: AppLocale) {
    if (locale === this._locale) {
      return
    }

    this._locale = this.localeOverride = locale
    this._localeChange.emit(locale)

    const pathSegments = location.pathname.split('/')
    let firstBasePathSegmentIndex = 1
    let updatedPath = false

    if (this._isLocalizedBuild) {
      pathSegments[1] = locale
      firstBasePathSegmentIndex = 2
      updatedPath = true
    }

    if (pathSegments[firstBasePathSegmentIndex] === 'material') {
      pathSegments[firstBasePathSegmentIndex + 1] =
        this.materialBookSlugForLocale
      // Remove any segments after the book slug, since they might be
      // locale-specific.
      pathSegments.splice(firstBasePathSegmentIndex + 2)
      updatedPath = true
    }

    if (updatedPath) {
      location.pathname = pathSegments.join('/')
    }
  }

  private get localeOverride(): AppLocale | undefined {
    return localStorage.getItem(localeOverrideStorageKey) as
      | AppLocale
      | undefined
  }

  private set localeOverride(locale: AppLocale | undefined) {
    if (locale) {
      localStorage.setItem(localeOverrideStorageKey, locale)
    } else {
      localStorage.removeItem(localeOverrideStorageKey)
    }
  }

  get materialBookSlugForLocale(): MaterialBookSlug {
    switch (this._locale) {
      case AppLocale.de:
        return MaterialBookSlug.de
      case AppLocale.en:
        return MaterialBookSlug.en
      case AppLocale.lt:
        return MaterialBookSlug.en
    }
  }
}
