import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { combineLatest, Observable } from 'rxjs'
import { map, pluck } from 'rxjs/operators'
import { ChapterInfo, findChapterInLevels } from '../core/contentful'
import { BookRouteComponent } from './book-route/book-route.component'
import { MaterialBookViewer } from './book-toc/book-toc.component'
import { draftQueryParam } from './material-routing-params'

@Component({
  selector: 'kdgh-material-chapter-route',
  template: `
    <kdgh-material-chapter-content
      *ngIf="chapterInfo$ | async as chapterInfo; else chapterNotFound"
      [viewer]="viewer"
      [chapterInfo]="chapterInfo"
    >
    </kdgh-material-chapter-content>

    <ng-template #chapterNotFound>
      <div class="not-found-message-container">
        <div class="message is-danger">
          <p class="message-body" i18n>
            Das Kapitel konnte nicht gefunden werden.
          </p>
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .not-found-message-container {
        display: flex;
        justify-content: center;
        padding: 2rem 1rem;
      }
    `,
  ],
})
export class ChapterRouteComponent {
  chapterInfo$: Observable<ChapterInfo | null>

  get viewer(): MaterialBookViewer {
    return this.bookRoute.viewer
  }

  constructor(
    private route: ActivatedRoute,
    private bookRoute: BookRouteComponent
  ) {
    const parentChapterSlug$ = this.route.params.pipe(
      pluck('parentChapterSlug')
    )
    const chapterSlug$ = this.route.params.pipe(pluck('chapterSlug'))
    const draft$ = this.route.queryParamMap.pipe(pluck(draftQueryParam))

    this.chapterInfo$ = combineLatest([
      parentChapterSlug$,
      chapterSlug$,
      this.bookRoute.book,
      draft$,
    ]).pipe(
      map(([parentChapterSlug, chapterSlug, book]) =>
        findChapterInLevels(
          chapter => chapter.fields.slug === (chapterSlug ?? parentChapterSlug),
          book.fields.levels
        )
      )
    )
  }
}
