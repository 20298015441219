import { Component } from '@angular/core'
import { UserService } from '../../core'

@Component({
  selector: 'kdgh-material-access-wall',
  templateUrl: './access-wall.component.html',
  styleUrls: ['./access-wall.component.scss'],
})
export class AccessWallComponent {
  constructor(public userService: UserService) {}

  signIn() {
    this.userService.signIn({ target: '/material' })
  }
}
