import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Entry } from 'contentful'
import { combineLatest, firstValueFrom, from, Observable } from 'rxjs'
import { concatMap, finalize, map, shareReplay } from 'rxjs/operators'
import { ContentfulService, MaterialBookFields } from '../../core/contentful'
import { MaterialBookViewer } from '../book-toc/book-toc.component'
import { saveContentOverview } from '../material-overview-table'
import { draftQueryParam } from '../material-routing-params'
import { MaterialBookSlug } from '../material-routing.module'

@Component({
  selector: 'kdgh-material-book-route',
  templateUrl: './book-route.component.html',
  styleUrls: ['./book-route.component.scss'],
})
export class BookRouteComponent implements OnInit {
  initialOpenedChapter: string[] = []
  showDraft: Observable<boolean>
  private materialBookSlug: Observable<MaterialBookSlug>
  book: Observable<Entry<MaterialBookFields> | null>
  loading = false

  get viewer(): MaterialBookViewer {
    return this.route.snapshot.data.viewer
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentful: ContentfulService
  ) {
    this.showDraft = this.route.queryParamMap.pipe(
      map(params => params.get(draftQueryParam) === 'true'),
      shareReplay(1)
    )
    this.materialBookSlug = this.route.paramMap.pipe(
      map(params => params.get('bookSlug') as MaterialBookSlug),
      shareReplay(1)
    )
    this.book = combineLatest([this.materialBookSlug, this.showDraft]).pipe(
      concatMap(([slug, draft]) => {
        this.loading = true
        return from(
          this.contentful.getMaterialBook({
            slug,
            draft,
          })
        ).pipe(
          finalize(() => {
            this.loading = false
          })
        )
      }),
      shareReplay(1)
    )
  }

  ngOnInit() {
    const parentChapterSlug =
      this.route.firstChild?.snapshot.params.parentChapterSlug
    if (parentChapterSlug) {
      this.initialOpenedChapter = [parentChapterSlug]
    }
  }

  onShowDraftChange(showDraft: boolean) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: showDraft ? { [draftQueryParam]: showDraft } : {},
    })
  }

  async downloadContentOverview() {
    const book = await firstValueFrom(this.book)
    const showDraft = await firstValueFrom(this.showDraft)
    await saveContentOverview(book, this.contentful, showDraft)
  }
}
