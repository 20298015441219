<kdgh-page>
  <kdgh-main-nav-bar></kdgh-main-nav-bar>

  <div class="container">
    <div class="section">
      <h1 class="title is-1" i18n>
        So erhalten Sie Zugang zu den Materialien im Mitgliederportal
      </h1>

      <div class="content">
        <ng-container i18n>
          Voraussetzung sind die Teilnahme an mindestens einem Kurs-Teil der
          Stufe I und Mitgliedschaft im Verein:
        </ng-container>

        <ol>
          <li>
            <a
              i18n
              href="https://www.katechesedesgutenhirten.de/kurse/naechste-kurse"
              >Kurse besuchen</a
            >
          </li>
          <li>
            <a
              i18n
              href="https://www.katechesedesgutenhirten.de/verein/mitglied-werden"
              >Vereinsmitglied werden</a
            >
          </li>
        </ol>

        <ng-container i18n>
          Wenn Sie die Voraussetzungen erfüllen, bekommen Sie mit folgenden
          Schritten Zugang zu den Materialien:
        </ng-container>

        <ol>
          <li>
            <a (click)="signIn()" i18n
              >Klicken Sie hier und erstellen Sie unter „Registrieren“ ein Konto
              (mit Ihrem Namen, Ihrer E-Mail-Adresse und einem von Ihnen
              vergebenen Passwort).</a
            >
          </li>
          <li i18n>
            Senden Sie die Bescheinigungen über die Kurse der Katechese des
            Guten Hirten an den Verein (anton.koller@cablenet.de).
          </li>
          <li i18n>
            Nachdem die Voraussetzungen (Mitgliedschaft und Zertifikate)
            überprüft wurden, erhalten Sie vom Verein eine E-Mail über die
            Freischaltung des Mitgliederportals (das die Website mit den
            Materialien enthält).
          </li>
          <li>
            <a (click)="signIn()" i18n
              >Anmelden mit Email-Adresse und Passwort</a
            >
          </li>
        </ol>
      </div>
    </div>
  </div>
</kdgh-page>
