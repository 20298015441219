<kdgh-page>
  <kdgh-main-nav-bar></kdgh-main-nav-bar>

  <ng-template #bookNotFound>
    <div *ngIf="!loading" class="not-found-message-container">
      <div class="message is-danger">
        <p class="message-body" i18n>
          Das Materialbuch konnte nicht gefunden werden.
        </p>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="book | async as book; else bookNotFound">
    <kdgh-sidenav-container #sidenav>
      <kdgh-sidenav>
        <div class="sidenav-content">
          <div class="sidenav-admin-actions" *ngIf="viewer.isAdmin">
            <div class="sidenav-admin-section-title mb-1">Admin</div>

            <div *ngIf="showDraft | async as showDraft" class="mb-1">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="showDraft"
                  [ngModel]="showDraft"
                  (ngModelChange)="onShowDraftChange($event)"
                />&nbsp;
                <ng-container i18n>Entwurf anzeigen</ng-container>
              </label>
            </div>
            <div>
              <button
                class="button is-small"
                (click)="downloadContentOverview()"
              >
                <span>Inhaltsübersicht</span>
              </button>
            </div>
          </div>

          <div class="scrollable-column-content">
            <kdgh-material-book-toc
              [viewer]="viewer"
              [book]="book"
              (selectChapter)="sidenav.isMobile && sidenav.close()"
              [openedChapterSlugs]="initialOpenedChapter"
            >
            </kdgh-material-book-toc>
          </div>
        </div>
      </kdgh-sidenav>

      <kdgh-sidenav-content>
        <router-outlet> </router-outlet>
      </kdgh-sidenav-content>
    </kdgh-sidenav-container>
  </ng-container>
</kdgh-page>
