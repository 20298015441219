import { Injectable } from '@angular/core'
import { createClient, Entry } from 'contentful'
import { environment } from '../../../environments/environment'
import {
  MaterialBookFields,
  MaterialChapterFields,
} from './material-book.types'

const config = environment.contentful

const deliveryClient = createClient({
  space: config.spaceId,
  accessToken: config.accessToken,
  environment: config.environment,
})

const previewClient = createClient({
  space: config.spaceId,
  accessToken: config.previewAccessToken,
  environment: config.environment,
  host: 'preview.contentful.com',
})

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  async getMaterialBook({
    slug,
    draft,
  }: {
    slug: string
    draft?: boolean
  }): Promise<Entry<MaterialBookFields> | null> {
    const {
      items: [book],
    } = await (draft
      ? previewClient
      : deliveryClient
    ).getEntries<MaterialBookFields>({
      // (Book) > Level > Chapter > SubChapter
      include: 3,
      content_type: 'materialBook',
      'fields.slug[in]': slug,
    })

    return book ?? null
  }

  async getMaterialChapter({
    id,
    draft,
  }: {
    id: string
    draft?: boolean
  }): Promise<Entry<MaterialChapterFields> | null> {
    const [chapter] = await this.getMaterialChapters({
      ids: [id],
      draft,
    })

    return chapter ?? null
  }

  async getMaterialChapters({
    ids,
    draft,
  }: {
    ids: string[]
    draft?: boolean
  }): Promise<Entry<MaterialChapterFields>[]> {
    const { items } = await (draft
      ? previewClient
      : deliveryClient
    ).getEntries<MaterialChapterFields>({
      // (Chapter) > MaterialList > Material
      include: 2,
      content_type: 'materialChapter',
      'sys.id[in]': ids.join(','),
    })

    return items.sort((a, b) => ids.indexOf(a.sys.id) - ids.indexOf(b.sys.id))
  }

  getEditUrlForEntry(id: string): string {
    return `${this.getEnvironmentEditUrl()}/entries/${id}`
  }

  getEnvironmentEditUrl(): string {
    return `https://app.contentful.com/spaces/${config.spaceId}/environments/${config.environment}`
  }
}
