import { Asset, Entry, EntryFields } from 'contentful'
import RichText = EntryFields.RichText

export interface MaterialBookFields {
  slug: string
  name: string
  levels: Entry<MaterialLevelFields>[]
}

export type MaterialLevel = '0' | 'I' | 'II' | 'III' | 'IV' | 'Publikationen'

export interface MaterialLevelFields {
  name: string
  level: MaterialLevel
  slug: string
  chapters: Entry<MaterialChapterFields>[]
}

export interface MaterialChapterFields {
  title: string
  slug: string
  heroImage: Asset
  chapters: Entry<MaterialChapterFields>[]
  description?: RichText
  body?: RichText
}

export interface MaterialListFields {
  name: string
  description: RichText
  materials?: Entry<MaterialFields>[]
}

export interface MaterialFields {
  name: string
  file: Asset
  materialType?: MaterialType
  grammatur?: number
  paperSiz?: PaperSize
  paperColor?: PaperColor
  printColor?: PrintColor
  duplexPrint?: boolean
  materialThickness?: number
}

export enum MaterialType {
  Sperrholz = 'Sperrholz',
  Pappel = 'Pappel',
  Papier = 'Papier',
  Karton = 'Karton',
}

export enum PaperSize {
  A1 = 'DIN A1',
  A2 = 'DIN A2',
  A3 = 'DIN A3',
  A4 = 'DIN A4',
  A5 = 'DIN A5',
  A6 = 'DIN A6',
}

export enum PaperColor {
  Grün = 'Grün',
  Rot = 'Rot',
  Blau = 'Blau',
  Weiß = 'Weiß',
  Orange = 'Orange',
}

export enum PrintColor {
  Farbig = 'Farbig',
  SchwarzWeiß = 'Schwarz/Weiß',
}

export type OrdinalPath = number[]

export interface ChapterInfo {
  level: Entry<MaterialLevelFields>
  parents: Entry<MaterialChapterFields>[]
  chapter: Entry<MaterialChapterFields>
  ordinalPath: OrdinalPath
}

export function findChapterInChapters(
  predicate: (chapter: Entry<MaterialChapterFields>) => boolean,
  chapters: Entry<MaterialChapterFields>[],
  level: Entry<MaterialLevelFields>,
  parents: Entry<MaterialChapterFields>[],
  parentOrdinalPath: number[]
): ChapterInfo | null {
  for (const [i, chapter] of chapters.entries()) {
    const ordinalPath = [...parentOrdinalPath, i]

    if (predicate(chapter)) {
      return {
        chapter,
        level,
        parents,
        ordinalPath,
      }
    }

    const childChapters = chapter.fields.chapters
    if (!childChapters) {
      continue
    }

    const childChapter = findChapterInChapters(
      predicate,
      childChapters,
      level,
      [...parents, chapter],
      ordinalPath
    )
    if (childChapter) {
      return childChapter
    }
  }

  return null
}

export function findChapterInLevels(
  predicate: (chapter: Entry<MaterialChapterFields>) => boolean,
  levels: Entry<MaterialLevelFields>[]
): ChapterInfo | null {
  for (const level of levels) {
    const childChapters = level.fields.chapters
    if (!childChapters) {
      continue
    }

    const chapter = findChapterInChapters(
      predicate,
      childChapters,
      level,
      [],
      []
    )
    if (chapter) {
      return chapter
    }
  }

  return null
}

export function formatOrdinalPath(path: OrdinalPath) {
  const ordinals = path.map((ordinal, i) => (i === 0 ? ordinal : ordinal + 1))
  if (ordinals.length === 1) {
    return ordinals[0] + '.'
  }
  return ordinals.join('.')
}
